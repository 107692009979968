import Vue from 'vue'
import VueRouter from 'vue-router'

import index_index from '../views/index/index.vue'
Vue.use(VueRouter)

const routes = [


  {
    path: '/',
    name: 'index',
    meta: {
      title: "大象文件预览",
    },
    component: index_index
  },
 
  


]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title

  }
  next()
})

export default router