var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagemain"},[_c('div',{staticClass:"file_div"},[_c('div',{staticClass:"file_inner"},[_c('div',{staticClass:"daxiang_title"},[_vm._v("大象系统文件预览")]),_c('div',{staticClass:"img_div_main"},[(
            _vm.content_type == 'application/vnd.ms-excel' ||
            _vm.content_type ==
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          )?_c('img',{staticClass:"img_div",attrs:{"src":require("@/assets/img/suffix/excel.png"),"alt":""}}):_vm._e(),(
            _vm.content_type == 'application/msword' ||
            _vm.content_type ==
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          )?_c('img',{staticClass:"img_div",attrs:{"src":require("@/assets/img/suffix/word.png"),"alt":""}}):_vm._e(),(_vm.content_type == 'application/pdf')?_c('img',{staticClass:"img_div",attrs:{"src":require("@/assets/img/suffix/pdf.png"),"alt":""}}):_vm._e(),(_vm.content_type == '')?_c('img',{staticClass:"img_div",attrs:{"src":require("@/assets/img/suffix/file2.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"filename"},[_c('div',{staticClass:"filename_txt"},[_vm._v(" "+_vm._s(_vm.filename)+" ")]),_c('div',{staticClass:"file_size"},[_vm._v("文件大小："+_vm._s(_vm.file_size))])]),(!_vm.loading_success)?_c('div',[_c('div',{staticClass:"detail_txt2"},[(_vm.tishi_flag)?_c('img',{staticClass:"loading_img",attrs:{"src":require("@/assets/img/suffix/loading.gif"),"alt":""}}):_c('Icon',{staticStyle:{"margin-right":"5px"},attrs:{"type":"md-warning","color":"red"}}),_c('div',{staticClass:"img_loading_txt"},[_vm._v(" "+_vm._s(_vm.tishi_txt)+" "),_c('span',{staticClass:"zhijie_xiazai",on:{"click":_vm.download_file}},[_vm._v("直接下载")])])],1)]):_c('div',{staticClass:"yulan_main"},[_c('div',{staticClass:"yulan_btn",on:{"click":_vm.download_file}},[_c('Icon',{attrs:{"type":"md-download"}}),_vm._v(" 下载文件 ")],1),_c('div',{staticClass:"yulan_btn",on:{"click":function($event){return _vm.open_url(_vm.view_url, '_self')}}},[_c('Icon',{attrs:{"type":"md-eye"}}),_vm._v(" 打开预览 ")],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }