import Vue from 'vue'
import App from './App.vue'
import router from './router'


// 引入组件库
import iView from 'iview';
import 'iview/dist/styles/iview.css';
Vue.use(iView);

// 引入cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


// 引入iconfont
import "@/assets/iconfont/iconfont.css"
// 字体
import "@/assets/font/font.css"

import "video.js/dist/video-js.css";




Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
