<template>
  <div class="pagemain">
    <div class="file_div">
      <div class="file_inner">
        <div class="daxiang_title">大象系统文件预览</div>
        <div class="img_div_main">
          <img
            class="img_div"
            v-if="
              content_type == 'application/vnd.ms-excel' ||
              content_type ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            "
            src="@/assets/img/suffix/excel.png"
            alt=""
          />
          <img
            class="img_div"
            v-if="
              content_type == 'application/msword' ||
              content_type ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            "
            src="@/assets/img/suffix/word.png"
            alt=""
          />
          <img
            class="img_div"
            v-if="content_type == 'application/pdf'"
            src="@/assets/img/suffix/pdf.png"
            alt=""
          />
          <img
            class="img_div"
            v-if="content_type == ''"
            src="@/assets/img/suffix/file2.png"
            alt=""
          />
        </div>
        <div class="filename">
          <div class="filename_txt">
            {{ filename }}
          </div>

          <div class="file_size">文件大小：{{ file_size }}</div>
        </div>

        <div v-if="!loading_success">
          <div class="detail_txt2">
            <img
              class="loading_img"
              v-if="tishi_flag"
              src="@/assets/img/suffix/loading.gif"
              alt=""
            />
            <Icon type="md-warning" v-else color="red" style="margin-right: 5px" />
            <div class="img_loading_txt">
              {{ tishi_txt }}
              <span class="zhijie_xiazai" @click="download_file">直接下载</span>
            </div>
          </div>
        </div>
        <div class="yulan_main" v-else>
          <div class="yulan_btn" @click="download_file">
            <Icon type="md-download" />&nbsp;下载文件
          </div>
          <div class="yulan_btn" @click="open_url(view_url, '_self')">
            <Icon type="md-eye" />&nbsp;打开预览
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request, open_new_page } from "../../utils/http";
const Base64 = require("js-base64").Base64;

export default {
  components: {},
  data() {
    return {
      object_name: "",
      tishi_flag: true,
      tishi_txt: "在线预览加载中",
      content_type: "",
      filename: "",
      down_url: "",
      view_url: "",
      loading_success: false,
      token: "",
      file_size: "0MB",
    };
  },
  created() {
    this.token = this.$cookies.get("dx_token");
  },

  mounted() {
    var query = this.$route.query;
    if (!query.object_name) {
      this.tishi_txt = "文件参数异常！";
      this.tishi_flag = false;
      return;
    }
    if (query.token) {
      this.token = query.token;
    }
    //
    this.token = this.$cookies.get("dx_token");

    var str_b64 = Base64.decode(query.object_name);
    this.object_name = str_b64;
    var that = this;

    setTimeout(() => {
      that.get_file_info();
    }, 10);
    // that.get_view_file_url();
  },
  updated() {
    this.token = this.$cookies.get("dx_token");
  },
  beforeDestroy() {},
  methods: {
    download_file() {
      var url = this.down_url;
      var name = this.filename;

      fetch(url)
        .then((_) => _.blob())
        .then((_) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(_);
          link.setAttribute("download", name); // 自定义文件名
          link.click();
        });
    },

    open_url(url, t) {
      open_new_page(url, t);
    },

    get_file_info() {
      let obj = {
        object_name: this.object_name,
        token: this.token,
      };

      var that = this;
      request("post", process.env.VUE_APP_HOST + "/api_export/file/file_info", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            var object_data = res.data.data.object_data;
            var is_office = res.data.data.is_office;
            console.log(object_data);
            that.down_url = object_data.url;
            that.filename = object_data.filename;
            that.content_type = object_data.content_type;
            that.file_size = object_data.size;
            if (is_office == 1) {
              that.get_view_file_url();
            } else {
              that.view_url = object_data.url
              that.loading_success = true;
            }
          } else {
            that.$Message.error(res.data.msg);
          }
        }
      );
    },


    // 获取office文件预览地址
    get_view_file_url() {
      let obj = {
        object_name: this.object_name,
        token: this.token,
      };
      this.loading_success = false;

      var that = this;
      request("post", process.env.VUE_APP_HOST + "/api_export/file/view_office_file", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.view_url = res.data.data.view_url;
            this.loading_success = true;
          } else {
            this.tishi_flag = false;

            this.tishi_txt = res.data.msg;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.zhijie_xiazai {
  cursor: pointer;
  font-size: 12px;
  color: #166ad0;
}

.file_size {
  margin-top: 5px;
}

.yulan_main {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.yulan_btn {
  width: 90px;
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  margin: 0 5px;
  line-height: 30px;
  color: #ffffff;
  background: #5399ef;
}

.yulan_btn:hover {
  background: #0466dd;
}

.img_div {
  width: 60px;
}

.filename_txt {
  word-wrap: break-word;
}

.filename {
  padding: 15px 20px 0 20px;
  font-size: 13px;
  font-weight: 300;
  color: #888;
}

.circular {
  width: 25px;
  height: 25px;
}

.daxiang_title {
  font-size: 16px;
  margin: 40px 0 30px 0;
  font-weight: 300;
  color: #666;
}

.loading_img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.file_inner {
  width: 250px;
}

.detail_txt2 {
  margin-top: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_div_main {
  display: flex;
  justify-content: center;
}

.img_loading_txt {
  color: #666;
  font-size: 13px;
}

.xitongtishi {
  position: absolute;
  bottom: 20px;
  width: 250px;
  height: 20px;
  color: #ccc;
}

.file_div {
  box-shadow: 0 0 5px #ededed;
  border-radius: 10px 50px 10px 10px;
  width: 250px;
  padding-bottom: 25px;
  background: #fff;
  border: 1px solid #f0f0f0;
}

.dito {
  height: 20px;
  width: 10px;
  background: #0466dd;
  border-radius: 3px;
  margin-right: 10px;
}

.news_card {
  background: #fff;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
}

.news_card:hover {
  box-shadow: 0 0 6px #89bfd3;
}

.news_date {
  text-align: right;
  margin-bottom: 10px;
  color: #bbbbbb;
  font-size: 13px;
  font-weight: 300;
}

.news_title_weight {
  height: 80px;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  word-break: break-all;
}

.news_title {
  text-indent: 1em;
  font-size: 14px;
  width: 270px;
  height: 135px;
  text-align: left;
  font-weight: 300;
  color: #888;
  padding: 10px 10px 30px 10px;
}

.news_img {
  width: 270px;
  height: 150px;
}

.slogn {
  font-family: "dingding02";
  font-size: 18px;
  color: #dcdcdc;
  padding-left: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

.yuedu_yuanwen {
  font-size: 12px;
  color: #ebebeb;
  text-align: right;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.beian {
  margin-top: 30px;
  font-size: 13px;
  color: #ccc;
  padding-top: 30px;
  border-top: 1px solid #e3e3e3;
}

.ydyw {
  position: relative;
  right: -25px;
}

.footer {
  padding: 50px 0 30px 0;
  background: #f6f6f6;
}

.card1 {
  padding: 15px 0;
  font-size: 30px;
  letter-spacing: 0.2em;
  margin: 0px 0 5px 0;
  color: #fff;
  background: #0466dd;
  font-family: "dingding01";
}

.head_menu_title {
  margin-right: 40px;
  font-size: 16px;
  color: #f3f3f3;
  font-weight: 600;
}

.video2 {
  -webkit-backface-visibility: hidden;
}
.pagemain {
  height: 100vh;
  display: flex;
  background: #f6f6f6;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.head_menu_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 100px;
}

.fotter_logo_right {
  display: flex;
  align-items: center;
}

.guanzhu {
  font-size: 22px;
  color: #ccc;
}

.gzh_img {
  padding: 3px;
  background: #fff;
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.fotter_logo {
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
}

.menu_card1 {
  background: #efefef;
  padding-top: 40px;
  padding-bottom: 50px;
}

.menu_card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.video_hint {
  color: #244267;
  background: #e9e9e900;
  font-size: 46px;
  font-weight: 600;
  font-family: "dingding02";
  width: 100vw;
  height: 100px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  z-index: 9999;
}

.logo {
  height: 65px;
  padding: 10px;
}

.news_title2 {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-bottom: 20px;
  justify-content: flex-start;
  font-size: 24px;
  color: #333;
  font-family: "dingding06";
}

.logo3 {
  height: 65px;
  padding: 10px;
}

.head_div {
  height: 60px;
  background-color: #0466dd25;
  width: 100vw;
  position: absolute;
  padding: 0 20px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}

.video_div {
  padding: 0;
  margin: 0;
}

#mp41 {
  object-fit: fill;
}
</style>
